import {
    SET_GROUP_LIVE_STREAM,
    REMOVE_GROUP_LIVE_STREAM,
    ADD_GROUP_MESSAGE,
    SET_LIVE_STREAM_LOADER,
    SET_START_STREAM,
    SET_IS_LIVE,
    SET_STREAM_JOINED,
    SET_STREAM_TOKEN,
    SET_CAN_MODEL_GO_LIVE,
    SET_SERVER_TAB_TOKEN,
    SET_ROOM,
    SET_PUB_ID,
    SET_PUB_PVT_ID,
    SET_SEND_GO_LIVE_STREAM_MESSAGE,
    SET_LIVE_STREAM_STATUS,
    START_LIVE_STREAM
} from '../actions/types'

const initialState = {
    isGroupLiveStreamStarted: false,
    groupMessages: [],
    isLoading: false,
    startStream: false,
    streamJoined: false,
    streamToken: '',
    canModelGoLive: true,
    serverTabToken: '',
    room: '',
    pubId: '',
    pubPvtId: '',
    sendGoLiveStreamMessage: false,
    liveStreamStatus: '',
    isLiveStreamStarted: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_GROUP_LIVE_STREAM: {
            return {
                ...state,
                isGroupLiveStreamStarted: true
            }
        }
        case REMOVE_GROUP_LIVE_STREAM: {
            return {
                isGroupLiveStreamStarted: false,
                groupMessages: [],
                isLoading: false,
                startStream: false,
                streamJoined: false,
                streamToken: '',
                canModelGoLive: true,
                serverTabToken: '',
                room: '',
                pubId: '',
                pubPvtId: '',
                sendGoLiveStreamMessage: false,
                liveStreamStatus: '',
                isLiveStreamStarted: false
            }
        }
        case ADD_GROUP_MESSAGE: {
            const index = state.groupMessages.findIndex((msg) => msg._id === action.payload._id)
            if (index === -1) {
                state.groupMessages.unshift(action.payload)
            }
            const groupMessages = state.groupMessages
            return {
                ...state,
                groupMessages: groupMessages
            }
        }
        case SET_LIVE_STREAM_LOADER: {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case SET_START_STREAM: {
            return {
                ...state,
                startStream: true
            }
        }
        case SET_IS_LIVE: {
            return {
                ...state,
                isLive: action.payload
            }
        }
        case SET_STREAM_JOINED: {
            return {
                ...state,
                streamJoined: true
            }
        }
        case SET_STREAM_TOKEN: {
            return {
                ...state,
                streamToken: action.payload
            }
        }
        case SET_CAN_MODEL_GO_LIVE: {
            return {
                ...state,
                setCanModelGoLive: action.payload
            }
        }
        case SET_SERVER_TAB_TOKEN: {
            return {
                ...state,
                serverTabToken: action.payload
            }
        }
        case SET_ROOM: {
            return {
                ...state,
                room: action.payload.room
            }
        }
        case SET_PUB_ID: {
            return {
                ...state,
                pubId: action.payload.pubId
            }
        }
        case SET_PUB_PVT_ID: {
            return {
                ...state,
                pubPvtId: action.payload.pubPvtId
            }
        }
        case SET_SEND_GO_LIVE_STREAM_MESSAGE: {
            return {
                ...state,
                sendGoLiveStreamMessage: action.payload
            }
        }
        case SET_LIVE_STREAM_STATUS: {
            return {
                ...state,
                liveStreamStatus: action.payload
            }
        }
        case START_LIVE_STREAM: {
            return {
                ...state,
                isLiveStreamStarted: action.payload
            }
        }
        default:
            return state
    }
}
