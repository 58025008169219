import moment from 'moment'
import momentTZ from 'moment-timezone'

export default function formatUserLastSeen(time) {
    try {
        let lastSeen
        let currentDate = moment().format('YYYY-MMM-DD').toString()
        let lastSeenDate = moment(time).format('YYYY-MMM-DD').toString()
        if (currentDate === lastSeenDate) {
            lastSeen = 'last seen ' + momentTZ(time).fromNow()
        } else {
            lastSeen = 'last seen ' + momentTZ(time).format('MMM D, h:mm A').toString()
        }
        return lastSeen
    } catch (error) {
        console.log(error)
    }
}