import {
    OPEN_COPY_TO_CLIPBOARD_TOAST,
    OPEN_COPY_TO_CLIPBOARD_TOAST_WITH_OFFSET,
    CLOSE_COPY_TO_CLIPBOARD_TOAST
} from './types'

export const openCopyToClipboardToast = (toastType = 'copy') => dispatch => {
    dispatch({
        type: OPEN_COPY_TO_CLIPBOARD_TOAST,
        payload: toastType
    })

    setTimeout(() => {
        dispatch({
            type: CLOSE_COPY_TO_CLIPBOARD_TOAST
        })
    }, 2000)
}

export const openCopyToClipboardToastWithOffset = (toastType = 'copy', offset = '') => dispatch => {
    dispatch({
        type: OPEN_COPY_TO_CLIPBOARD_TOAST_WITH_OFFSET,
        payload: { toastType, offset }
    })

    setTimeout(() => {
        dispatch({
            type: CLOSE_COPY_TO_CLIPBOARD_TOAST
        })
    }, 2000)
}
