import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import NewMassMsgDialog from '../chat-new/NewMassMsgDialog'
import WelcomeMessage from '../chat-new/WelcomeMessage'
import Button from '../Layouts/Button'
import { getUserList, setSearchInputValue, setSelectedChatModelId } from '../../actions/chatActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

const MainDiv = styled.div`
    background: ${props => props.backgroundColor};
    z-index: 2;
    border-bottom: 1px solid ${props => props.borderColor};

    .search-icon {
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
    
    .close-icon {
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
        cursor: pointer;
        background-color: rgba(0,0,0,0.2);
        height: 22px;
        width: 22px;
        border-radius: 15px;
    }
        
    .fa-search {
        cursor: pointer;
        padding-left: 20px;
    }

    input, select {
        width: 100%;
        height: 34px;
        font-size: 14px;
        padding: 6px 8px;
        color: black;
        background: white;
    }

    .select-wrapper {
        width: 100%;
    }

    .fa-chevron-down {
        color: #000000 !important;
        pointer-events: none;
    }

    input {
        border: 1px solid rgba(0,0,0,0.7);
        border-radius: 5px;
        padding-right: 60px;
        :focus {
            outline: none;
        }
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid rgba(0,0,0,0.7);
        background: white;
    }

    select:focus-visible {
        outline: none;
    }
            
    .select-wrapper:first-of-type select {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .select-wrapper:last-of-type select {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .fa-sort-amount-down {
        font-size: 18px;
        color: rgba(0,0,0,0.7);
        left: 10px; 
        top: 50%;  
        transform: translateY(-50%);
        pointer-events: none; 
    }
    
    .first-select {
        padding-left: 35px;
    }
    

    .fa-search {
        color: rgba(0,0,0,0.7);
    }
    
    .fa-times{
       color: rgba(0,0,0,0.4);
       margin: 3.7px 0px 0px 5.7px;
    }

    .fa-chevron-down {
        font-size: 14px;
        top: 50%;
        transform: translateY(calc( -50% + 2px));
        right: 10px;
    }

    #sort-by {
        flex: 20;
    }
    
    #sort-by-subscribers {
        flex: 12;
    }

    .second-select {
        border-left: none;
    }

    @media(max-width: 576px) {
        select.small-select {
            font-size: 11px;
        }
        .first-select {
            padding-left: 30px
        }
        .fa-chevron-down {
            font-size: 12px;
            right: 8px;
        }
        .search-icon {
            display: none;
        }
        .close-icon {
            right: 8px;
        }
        .fa-sort-amount-down {
            left: 8px;
        }
    }
`

// Helper Function
function getSortByOptions(
    role,
    hideEarnings,
    isEnableSortBySubscriptionExpire,
    chatBookingEnabled,
    chatSettings,
    chatBookingTimer
) {
    let options = []
    if (role === 'content_manager' || role === 'live_stream_manager') {
        hideEarnings = true
    }

    if (role === 'admin' || role === 'sub_admin') {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Amount Spent', value: 'total_amount_spent' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' },
            { label: 'Days until Subscription Expires', value: 'expiration_date' }
        ]
    } else if (role === 'content_manager' && hideEarnings) {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' }
        ]
    } else if (role === 'live_stream_manager') {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' }
        ]
    } else if (role === 'model' && isEnableSortBySubscriptionExpire) {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Amount Spent', value: 'total_amount_spent' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' },
            { label: 'Days until Subscription Expires', value: 'expiration_date' }
        ]
    } else {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Amount Spent', value: 'total_amount_spent' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' }
        ]
    }

    if (chatBookingEnabled === true && (chatSettings && chatSettings.disabled_normal_chat === true)) {
        options.push({
            label: 'Filter By Allow User to Chat',
            value: 'FilterByChatAllowedUser'
        })
    }
    if (chatBookingTimer && chatBookingTimer.numberOfLiveChatUser > 0) {
        options.push({
            label: 'Filter By Live chats',
            value: 'FilterByLiveChats'
        })
    }

    const sortByOptions = options.map(option => (
        <option key={option.label} value={option.value}>
            {option.label}
        </option>
    ))
    return sortByOptions
}

// Helper Function
function getSortBySubscribersOptions() {
    const options = [
        { label: 'All', value: 'all' },
        { label: 'Active', value: '2' },
        { label: 'Active-Cancelled', value: '1' }
    ]
    const sortBySubscribersOptions = options.map(option => (
        <option key={option.label} value={option.value}>
            {option.label}
        </option>
    ))
    return sortBySubscribersOptions
}

// @ React Component
const ChatFilter = (props) => {

    const inputRef = useRef()
    const { auth, disabled, colorScheme, chat } = props
    const { searchedUsername, isLoading } = chat
    const {
        chatFilterBackgroundColor,
        chatFilterButtonBackgroundColor,
        chatFilterButtonFontColor,
        chatUserListItemFontColor,
        chatBorderColor
    } = colorScheme
    const { appSettings, user } = auth
    const {
        card_background_color,
        site_font_color,
        chat_booking_enabled,
        chat_settings,
        hide_earnings,
        is_enable_sort_by_subscription_expire
    } = appSettings
    const role = user.role
    const enableSortBySubscribers = role === 'admin' ||
        role === 'sub_admin' ||
        (role === 'model' && is_enable_sort_by_subscription_expire)
    const selectClass = enableSortBySubscribers ? 'small-select' : ''

    const [isOpenMassMessageDialog, setIsOpenMassMessageDialog] = useState(false)
    const [isOpenWelcomeMessageDialog, setIsOpenWelcomeMessageDialog] = useState(false)
    const [isHelperModalOpen, setIsHelperModalOpen] = useState(false)
    const [sortBy, setSortBy] = useState(props.chat.sortBy ? props.chat.sortBy : 'last_message_time')
    const [sortBySubscribers, setSortBySubscribers] = useState(props.chat.sortBySubscribers ? props.chat.sortBySubscribers : 'all')
    const chatBookingTimer = props.chatBookingTimer

    const sortByOptions = getSortByOptions(
        role,
        hide_earnings,
        is_enable_sort_by_subscription_expire,
        chat_booking_enabled,
        chat_settings,
        chatBookingTimer
    )
    const sortBySubscribersOptions = getSortBySubscribersOptions()

    const handleChange = (e) => {
        let fieldName = e.target.name
        let value = e.target.value
        let _sortBySubscribers = props.chat.sortBySubscribers
        let _sortBy = props.chat.sortBy

        if (fieldName === 'sort_by' && value === 'expiration_date' && _sortBySubscribers !== '1') {
            _sortBySubscribers = '1'
            setSortBySubscribers(_sortBySubscribers)
        } else if (fieldName === 'sort_by_subscribers' && _sortBy === 'expiration_date') {
            value = '1'
        }

        if (fieldName === 'sort_by_subscribers') {
            _sortBySubscribers = value
            setSortBySubscribers(_sortBySubscribers)
        } else if (fieldName === 'sort_by') {
            _sortBy = value
            setSortBy(_sortBy)
        }

        props.getUserList({
            pageNum: 1,
            userName: searchedUsername,
            sortBy: _sortBy,
            isFilter: true,
            sortBySubscribers: _sortBySubscribers
        })
        props.setPage(1)
    }

    useEffect(() => {
        const modelId = appSettings.model_id
        props.setSelectedChatModelId(modelId)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isLoading) {
            props.getUserList({
                pageNum: 1,
                userName: searchedUsername,
                sortBy: sortBy,
                isFilter: true,
                sortBySubscribers: props.chat.sortBySubscribers
            })
            props.setPage(1)
        }
    }

    const clearInput = () => {
        if (!isLoading) {
            props.setSearchInputValue({ searchedUsername: '' })
            props.getUserList({
                pageNum: 1,
                userName: '',
                sortBy: sortBy,
                isFilter: true,
                sortBySubscribers: props.chat.sortBySubscribers
            })
            props.setPage(1)
            inputRef.current.focus()
        }
    }

    const setIsPopupOpen = (isOpen) => {
        if (window.innerWidth <= 576) props.setIsPopupOpen(isOpen)
    }

    return (
        <>
            <MainDiv
                className='p-2 position-sticky top-0 left-0'
                backgroundColor={chatFilterBackgroundColor}
                fontColor={chatUserListItemFontColor}
                borderColor={chatBorderColor}>
                <div className='position-relative mb-2'>
                    <form onSubmit={handleSubmit} action='.'>
                        <input
                            type='search'
                            id='search'
                            name='search'
                            ref={inputRef}
                            value={searchedUsername}
                            onChange={(e) => { props.setSearchInputValue({ searchedUsername: e.target.value }) }}
                            disabled={disabled}
                            placeholder='Search by username or nickname'
                            autoComplete='off'
                        />
                        <div className='search-icon position-absolute'>
                            <i className='fas fa-search ps-2 py-1'
                                onClick={handleSubmit}></i>
                        </div>
                        {
                            searchedUsername &&
                            <div className='close-icon position-absolute' onClick={clearInput}>
                                <i className='fas fa-times'></i>
                            </div>
                        }
                    </form>
                </div>
                <div className='d-flex mb-2 align-items-center' >
                    <span id='sort-by' className='select-wrapper position-relative'>
                        <i className='fas fa-sort-amount-down position-absolute'></i>
                        <select
                            className={`${selectClass} first-select`}
                            name='sort_by'
                            value={sortBy}
                            onChange={handleChange}
                            disabled={disabled}>
                            {sortByOptions}
                        </select>
                        <i className='fas fa-chevron-down position-absolute'></i>
                    </span>
                    {enableSortBySubscribers &&
                        <span id='sort-by-subscribers' className='select-wrapper position-relative'>
                            <select
                                className={`${selectClass} second-select`}
                                name='sort_by_subscribers'
                                value={sortBySubscribers}
                                onChange={handleChange}
                                disabled={disabled}>
                                {sortBySubscribersOptions}
                            </select>
                            <i className='fas fa-chevron-down position-absolute'></i>
                        </span>
                    }
                </div>
                {
                    role !== 'live_stream_manager' &&
                    <div className='d-flex justify-content-between'>
                        <Button
                            backgroundColor={chatFilterButtonBackgroundColor}
                            fontColor={chatFilterButtonFontColor}
                            classes='m-0 col'
                            onClick={() => {
                                setIsPopupOpen(true)
                                setIsOpenMassMessageDialog(true)
                            }}
                            loading={disabled}>
                            Send Mass Message
                        </Button>
                        <Button
                            backgroundColor={chatFilterButtonBackgroundColor}
                            fontColor={chatFilterButtonFontColor}
                            classes='m-0 col ml-2'
                            onClick={() => {
                                setIsPopupOpen(true)
                                setIsOpenWelcomeMessageDialog(true)
                            }}
                            loading={disabled}>
                            Welcome Message
                        </Button>
                    </div>
                }
            </MainDiv>

            {/* TODO: update user list after sending mass message ? */}
            {/* Dialog to send Mass Message */}
            {
                isOpenMassMessageDialog &&
                <NewMassMsgDialog
                    handleClose={() => {
                        setIsPopupOpen(false)
                        setIsOpenMassMessageDialog(false)
                    }}
                />
            }

            {/* Dialog for Welcome Message */}
            {
                isOpenWelcomeMessageDialog &&
                <WelcomeMessage
                    handleWelcomeMassDlgClose={() => {
                        setIsPopupOpen(false)
                        setIsOpenWelcomeMessageDialog(false)
                    }}
                    setHelperModelOpen={() => setIsHelperModalOpen(true)}
                />
            }

            {/* Helper modal */}
            {
                isHelperModalOpen &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                <h5 className='modal-title'>Welcome Message </h5>
                                <button
                                    className='close'
                                    onClick={() => setIsHelperModalOpen(false)}
                                    style={{ color: site_font_color, opacity: 1 }}
                                />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <p>Send an automated welcome message to all new subscribers. This message can optionally include content and can be free or paid. You’ll find two options which determines when we send the message:</p>
                                <ul>
                                    <li><b>Delay once they subscribe:</b> this option allows you to set a time interval, in hours, which will delay the message so that it appears more organic. For example, you might choose to delay the message by 2.5 hours after the user subscribes. If you choose this option, the user receives the message after the delay interval.</li>
                                    <li><b>Immediately once they subscribe:</b> choose this option if you want the subscriber to see the message as soon as they subscribe.</li>
                                </ul>
                                <p>If you choose the WELCOME MESSAGE button after you already created a welcome message, you’ll have the option to edit the message. You can remove the content, change the caption, adjust the price, and modify the send options.</p>
                                <p>There is now a new ‘Welcome Messages’ link in Reporting. This will show the current Welcome Message at the top of the list and all past versions of the Welcome Message. You can PAUSE the current message thereby stopping future subscribers from seeing the message. You can also MAKE LIVE any past Welcome Messages.</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

ChatFilter.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    chatBookingTimer: PropTypes.object.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    getUserList: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    setPage: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    setSearchInputValue: PropTypes.func.isRequired,
    setIsPopupOpen: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    chatBookingTimer: state.chatBookingTimer
})

export default connect(
    mapStateToProps,
    {
        setSelectedChatModelId,
        getUserList,
        setSearchInputValue
    }
)(withRouter(ChatFilter))
