import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import GalleryCarousel from './GalleryCarousel'

const ModalStyle = styled.div`
    width: 100%;
    z-index: 9999 !important;

    .modal {
        display: block;
        background-color: #000000e6;
    }
`

const CloseButton = styled.button`
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    border-radius: 77%;
    height: 30px;
    width: 30px;
    right: 10px; 
    position: absolute; 
    top: 20px;
    cursor: pointer;
    z-index: 10;
    display: inherit;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

const BlogGalleryDialog = (props) => {
    const isPreviewEnable = _.get(props, 'isPreviewEnable', false)
    const isLocked = _.get(props, 'isLocked', false)

    let showImages = 0

    if (props.contentFrom !== 'blog') {
        showImages = isPreviewEnable === true && isLocked === true ? [props.previewEnableImages[0]] : props.galleryImages
    } else {
        showImages = isPreviewEnable === true && isLocked === true ? [props.media[0]] : props.media
    }

    const onCloseDialog = () => {
        let isVideoExists = false
        for (let i = 0; i < showImages.length - 1; i++) {
            if (showImages[i].content_type === 'video') {
                isVideoExists = true
            }

        }
        if (isVideoExists === true) {
            document.querySelectorAll('.myVideoPause').forEach(vid => vid.pause())
        }

        props.closeDialogBlog()
    }

    useEffect(() => {
        const close = (event) => {
            (event.keyCode === 27) && props.closeDialogBlog()
        }
        window.addEventListener('keydown', close)
        return () => {
            window.removeEventListener('keydown', close)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('mousedown', onClickOutSideContent)
        return () => window.removeEventListener('mousedown', onClickOutSideContent)
    }, [props.contentFrom])

    const onClickOutSideContent = (e) => {
        let node = e.target
        let inside = false
        while (node) {
            if (node.classList.contains('gallery-content-overlay')) {
                inside = true
                break
            }
            node = node.parentElement
        }
        node = e.target
        while (node) {
            if (node.classList.contains('fa-chevron-left')) {
                inside = true
                break
            }
            node = node.parentElement
        }
        node = e.target
        while (node) {
            if (node.classList.contains('fa-chevron-right')) {
                inside = true
                break
            }
            node = node.parentElement
        }
        node = e.target
        while (node) {
            if (node.classList.contains('sc-frDJqD')) {
                inside = true
                break
            }
            node = node.parentElement
        }
        node = e.target
        while (node) {
            if (node.classList.contains('sc-hmzhuo')) {
                inside = true
                break
            }
            node = node.parentElement
        }
        if (!inside) {
            onCloseDialog()
        }
    }

    let media = []
    if (props.contentFrom === 'massMessage' || props.contentFrom === 'blog' || props.contentFrom === 'chat' || props.contentFrom === 'liveStream') {
        media = _.get(props, 'media', [])
    }
    if (media.length === 0) {
        for (let element of showImages) {
            media.push({ url: element, content_type: 'photo' })
        }
    }
    return <ModalStyle className='media-popup'>
        <div className='modal fade show'>
            <div className="modal-dialog modal-fullscreen" style={{ width: '100%', margin: '0%', maxWidth: '100%' }}>
                <div className="modal-content" style={{ backgroundColor: 'transparent' }}>
                    <div className="modal-body" style={{ backgroundColor: 'transparent', margin: '0', padding: '0', height: '100vh' }}>
                        <CloseButton
                            className='close'
                            content_color={props.auth.appSettings.content_color}
                            content_font_color={props.auth.appSettings.content_font_color}
                            onClick={onCloseDialog}
                        />
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <GalleryCarousel media={media} currentIndex={props.galleryIndex} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalStyle>
}

BlogGalleryDialog.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    galleryImages: PropTypes.array.isRequired,
    closeDialogBlog: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    galleryIndex: PropTypes.number.isRequired,
    isPreviewEnable: PropTypes.bool,
    previewEnableImages: PropTypes.array,
    isLocked: PropTypes.bool,
    contentCount: PropTypes.object,
    thumbImages: PropTypes.array,
    media: PropTypes.array,
    contentFrom: PropTypes.string
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(BlogGalleryDialog)
