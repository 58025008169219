import React, { useEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSpan = styled.span`
    /*Disables default tooltip on safari*/
    &::after {
        content: '';
        display: block;
    }
`

const LastMessage = ({ lastMessage }) => {

    const ref = useRef(null)
    const [showTooltip, setShowTooltip] = useState(false)

    useEffect(() => {
        if (ref && ref.current) {
            const { offsetWidth, scrollWidth } = ref.current
            // Show tooltip for only truncated text
            if ((offsetWidth < scrollWidth) && window.screen.width > 991) {
                setShowTooltip(true)
            }
        }
    }, [ref])

    return (
        <>
            <StyledSpan ref={ref} className='last-message' data-tip={showTooltip ? lastMessage : ''}>{lastMessage}</StyledSpan>
            {
                showTooltip &&
                <ReactTooltip className='tooltip' place='right' type='light' effect='solid' />
            }
        </>
    )
}

LastMessage.propTypes = {
    lastMessage: PropTypes.string.isRequired
}

export default LastMessage
