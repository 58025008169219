import React from 'react';
import { useStopwatch } from 'react-timer-hook';

function Stopwatch() {
    const {
        seconds,
        minutes,
        hours
    } = useStopwatch({ autoStart: true });

    return (
        <div>{hours > 0 && <>{hours < 10 ? `0${hours}` : hours}:</>}{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
    )
}

export default Stopwatch
