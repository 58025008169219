import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import {
    deleteMessageFromAll,
    deleteMessageFromSubscriber,
    getMessages,
    refreshBlurImage,
    deleteMessageAction,
    rotateImage,
    sendVideoProcessingRequest,
    unlockMessageContentForUser,
    deleteAllMessageAction
} from '../../actions/chatActions'
import styled from 'styled-components'
import {
    setSweetAlert,
    setConfirmSweetAlert,
    removeConfirmSweetAlert,
    setAlertLoader
} from '../../actions/sweetAlertActions'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'

const Dropdown = styled.div`
    z-index: 2 !important;
    right: 0;
    left: auto !important;
    transform: translateY(${props => props.translateY});
`

const DropdownItem = styled.button`
    &:hover, &:active, &:focus {
        background: rgb(54,54,54) !important;
        color: white !important;
    }
`

const MessageSettings = (props) => {
    const { message, auth, indexInfo, onClick, admin, chat } = props
    const { isAdmin, role } = auth.user
    const [messageIndex, activeSettingForMessageIndex] = indexInfo
    const [showConfirmAlert, setConfirmAlert] = useState(false)
    const [confirmAlertFor, setConfirmAlertFor] = useState('')
    const [loading, setLoading] = useState(false)

    const isSenderAdmin = isAdmin && message.fromAdmin
    const showRefreshBlurSetting = !message.isError && auth.isModel === false && role !== 'content_manager' && (message.isLocked === 'locked' || message.isLocked === 'unlocked')
    const showRotateImageSetting = !message.isError && message.type === 'photo'
    const showUnlockContentSettings = !message.isError && message.isLocked === 'locked'
    const showDeleteMessageFromSubscriberSettings = message.isLocked !== 'unlocked' && message.messageType !== 'GO_LIVE_STREAM'
    const showDeleteFromAllSettings = message.isMassMessage === true && message.isLocked !== 'unlocked'
    const showVideoProcessRequestSettings = auth.isModel === false && role !== 'content_manager' && message.type === 'video' && message.processing === true
    let doesMessageHaveSettings = showRefreshBlurSetting
        || showRotateImageSetting
        || showUnlockContentSettings
        || showDeleteMessageFromSubscriberSettings
        || showDeleteFromAllSettings
        || showVideoProcessRequestSettings

    if (message.type === 'GO_LIVE_STREAM' || message.processing) doesMessageHaveSettings = false

    const settingsInfo = {
        'refresh-blur': { icon: 'fa-sync', text: 'Refresh Blur Image' },
        'rotate-image-left': { icon: 'fa-undo', text: 'Rotate Image Left' },
        'rotate-image-right': { icon: 'fa-redo-alt', text: 'Rotate Image Right' },
        'unlock-content-for-user': { icon: 'fa-unlock', text: `Unlock ${message.type} for this Subscriber` },
        'delete-from-subscriber': { icon: 'fa-trash', text: 'Delete Message from Subscriber' },
        'delete-from-all': { icon: 'fa-trash', text: 'Delete Message from All' },
        'video-process-request': { icon: 'fa-sync', text: 'Send Video Process Request' }
    }

    const getSettings = (option, onClickHandler) => {
        const optionInfo = settingsInfo[option]
        return (
            <DropdownItem
                className='dropdown-item'
                onClick={onClickHandler}
                disabled={admin.isBlurLoading}>
                <i className={`fas ${optionInfo.icon}`}></i>&nbsp;&nbsp;{optionInfo.text}
            </DropdownItem>
        )
    }

    const handleRefreshBlur = async () => {
        const payload = { id: message._id, type: 'message' }
        setLoading(true)
        await props.refreshBlurImage(payload)
        // Hide settings dropdown
        onClick(-1)
        setLoading(false)
    }

    const handleRotateImage = async (direction) => {
        const payload = {
            id: message._id,
            type: 'message',
            rotate_angle: direction === 'left' ? -90 : 90,
            userId: props.chat.selectedUserId
        }
        setLoading(true)
        await props.rotateImage(payload)
        // Hide settings dropdown
        onClick(-1)
        setLoading(false)
    }

    const unlockContentForUser = async () => {
        const receiverId = props.chat.selectedUserId
        const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
            ? chat.userProfileInfo[receiverId]
            : getCloudFrontAssetsUrl('faces/avatar.png')
        setConfirmAlert(true)
        setConfirmAlertFor('unlock-for-user')
        props.setConfirmSweetAlert({
            description: 'Are you sure you want to unlock this content for free to ' + receiverProfileDetails.name + ' ?'
        })
    }

    const deleteMsgFromSubscriber = async () => {
        setConfirmAlert(true)
        setConfirmAlertFor('delete-from-subscriber')
        props.setConfirmSweetAlert({
            description: 'Are you sure you want to delete this message?'
        })
    }

    const deleteMsgFromAll = async () => {
        setConfirmAlert(true)
        setConfirmAlertFor('delete-from-all')
        props.setConfirmSweetAlert({
            description: 'Are you sure you want to delete this message?'
        })
    }

    const sendVideoProcessingRequest = async () => {
        const contentURL = message.mediaUrl
        const payload = {
            udid: message.udid,
            videoFrom: 'message',
            isMassMessage: message.isMassMessage,
            s3FilePath: contentURL,
            shouldGenerateBlurImage: true
        }
        await props.sendVideoProcessingRequest(payload)
        // Hide settings dropdown
        onClick(-1)
    }

    const handleClick = () => {
        if (admin.isBlurLoading) return
        const activeIndex = activeSettingForMessageIndex === messageIndex ? -1 : messageIndex
        onClick(activeIndex)
    }

    let translateY = '-34px'
    if (activeSettingForMessageIndex === messageIndex) {
        const msg = document.getElementById(activeSettingForMessageIndex)
        const msgBottomPosition = msg.getBoundingClientRect().bottom
        const msgContainer = document.getElementById('input-section')
        const msgContainerBottomPosition = msgContainer.getBoundingClientRect().bottom

        if (msgBottomPosition + 200 > msgContainerBottomPosition) {
            translateY = '-108%'
        }
    }

    const handleUnlockContentForUser = async () => {
        const payload = { message_id: message._id }
        await props.unlockMessageContentForUser(payload)
        props.removeConfirmSweetAlert()
        // Hide settings dropdown
        onClick(-1)
    }

    const handleDeleteMsgFromSubscriber = async () => {
        const payload = { message_id: message._id }
        await props.deleteMessageFromSubscriber(payload)
        props.deleteMessageAction(message._id, props.chat.selectedUserId)
        props.removeConfirmSweetAlert()
        // Hide settings dropdown
        onClick(-1)
    }

    const handleDeleteMsgFromAll = async () => {
        const payload = { message_id: message._id }
        await props.deleteMessageFromAll(payload)
        props.deleteAllMessageAction(message.udid)
        props.removeConfirmSweetAlert()
        // Hide settings dropdown
        onClick(-1)
    }

    const onConfirmSweetAlert = () => {
        props.setAlertLoader(true)
        switch (confirmAlertFor) {
            case 'unlock-for-user':
                handleUnlockContentForUser()
                break
            case 'delete-from-subscriber':
                handleDeleteMsgFromSubscriber()
                break
            case 'delete-from-all':
                handleDeleteMsgFromAll()
                break
            default:
                break
        }
    }

    if (isAdmin && isSenderAdmin && role !== 'live_stream_manager') {
        return (
            <>
                {
                    doesMessageHaveSettings
                    && <i className='fas fa-ellipsis-v position-absolute text-small' onClick={handleClick}></i>
                }
                {
                    messageIndex === activeSettingForMessageIndex
                    && <Dropdown translateY={translateY} className='dropdown-menu show'>
                        {
                            loading
                                ? <div className='d-flex justify-content-center'><span className='spinner-border' role='status'></span></div>
                                : <>
                                    {
                                        showRefreshBlurSetting
                                        && getSettings('refresh-blur', () => handleRefreshBlur())
                                    }
                                    {
                                        showRotateImageSetting
                                        && getSettings('rotate-image-left', () => handleRotateImage('left'))
                                    }
                                    {
                                        showRotateImageSetting
                                        && getSettings('rotate-image-right', () => handleRotateImage('right'))
                                    }
                                    {
                                        showUnlockContentSettings
                                        && getSettings('unlock-content-for-user', () => unlockContentForUser())
                                    }
                                    {
                                        showDeleteMessageFromSubscriberSettings
                                        && getSettings('delete-from-subscriber', () => deleteMsgFromSubscriber())
                                    }
                                    {
                                        showDeleteFromAllSettings
                                        && getSettings('delete-from-all', () => deleteMsgFromAll())
                                    }
                                    {
                                        showVideoProcessRequestSettings
                                        && getSettings('video-process-request', () => sendVideoProcessingRequest())
                                    }
                                </>
                        }
                    </Dropdown>
                }
                {
                    showConfirmAlert &&
                    <ConfirmSweetAlert
                        onConfirm={() => { onConfirmSweetAlert() }}
                        onCancel={() => {
                            props.removeConfirmSweetAlert()
                            setConfirmAlert(false)
                        }}
                    />}
            </>
        )
    }
}

MessageSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    admin: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    indexInfo: PropTypes.array.isRequired,
    rotateImage: PropTypes.func.isRequired,
    unlockMessageContentForUser: PropTypes.func.isRequired,
    deleteMessageFromSubscriber: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
    deleteMessageFromAll: PropTypes.func.isRequired,
    refreshBlurImage: PropTypes.func.isRequired,
    sendVideoProcessingRequest: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired,
    setAlertLoader: PropTypes.func.isRequired,
    deleteMessageAction: PropTypes.func.isRequired,
    deleteAllMessageAction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    admin: state.admin,
    chat: state.chat
})

export default connect(
    mapStateToProps,
    {
        rotateImage,
        unlockMessageContentForUser,
        getMessages,
        deleteMessageFromSubscriber,
        deleteMessageFromAll,
        refreshBlurImage,
        sendVideoProcessingRequest,
        setSweetAlert,
        setConfirmSweetAlert,
        removeConfirmSweetAlert,
        setAlertLoader,
        deleteMessageAction,
        deleteAllMessageAction
    }
)(withRouter(MessageSettings))
