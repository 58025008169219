import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import BlogGalleryDialog from './BlogGalleryDialog'
import FullScreenModelPopUpDialog from './FullScreenModelPopUpDialog'

function PreviewImageDailog(props) {
    const { classes } = props

    return <>
        {props.type === 'gallery' ?
            <BlogGalleryDialog
                galleryImages={props.galleryImages}
                closeDialogBlog={() => { props.updateIsBlogOpen(false) }}
                classes={classes}
                userId={props.auth.user._id}
                galleryIndex={0}
                thumbImages={props.thumbImages}
                media={props.media}
                contentFrom={props.contentFrom}
            />
            :
            <FullScreenModelPopUpDialog
                url={props.type === 'photo' ? props.blogUrl : props.blogVideoUrl}
                handleClose={() => props.updateIsBlogOpen(false)}
                type={props.type}
            />
        }
    </>
}

PreviewImageDailog.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    galleryImages: PropTypes.array,
    updateIsBlogOpen: PropTypes.func.isRequired,
    isBlogOpen: PropTypes.bool,
    blogUrl: PropTypes.string,
    blogVideoUrl: PropTypes.string,
    type: PropTypes.string,
    thumbImages: PropTypes.array,
    media: PropTypes.array,
    contentFrom: PropTypes.string
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(withRouter(PreviewImageDailog))
