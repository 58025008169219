import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import formatUserLastSeen from '../../utils/formatUserLastSeen'
import socket from '../../utils/socket'
import LiveStream from './LiveStream'
import { setIsChatScreenOpen, setSelectedUserId, updateUserLastSeen, updateUserAmountSpent } from '../../actions/chatActions'

const ChatNewHeader = styled.div`
    pointer-events: ${props => props.isLoading === 'true' ? 'none' : 'auto'};
    position: sticky;
    min-height: 74px;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor};
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .flex-1 {
        flex: 1;
        overflow: hidden;
    }

    .heading-sm {
        font-size: 18px;
    }

    .wallet-amount {
        padding-left: 0.8px;
    }

    .user-name {
        padding-bottom: 4px !important;
        line-height: initial;
        height: auto;
    }

    .amount {
        font-size: 14px;
        padding-bottom: 4px !important;
    }

    @media(max-width: 576px){
        min-height: 64px;

        .user-name {
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media(max-width: 767px) {
                max-width: calc(100% - ${props => props.showFullText ? (props.showViewGoLiveButton ? '90px' : '50px') : '130px'});
                width: calc(100vw - 40px);
            }
        }

        .amount {
            font-size: 13px;
        }
    }
`

const Image = styled.img`
    height: 40px !important;
    border-radius: 20px;
`

const UserLastSeen = styled.div`
    font-weight: 400;
    font-size: 11px;
    position: relative;
    left: ${props => props.lastSeen === 'Online' ? '-5px' : '0px'};
    line-height: initial;

    @media(max-width: 576px){
        font-size: 10px;
        left: 0px;

        svg {
            height: 10px;
            width: 10px;
            margin-right: 5px;
        }

        circle {
            cx: 5px;
            cy: 5px;
        }
    }
`

const UserNickName = styled.span`
    color: #4169e1;
`

const Earning = styled.a`
    color: #32CD32;
    text-decoration: underline;
    white-space: nowrap;

    :hover {
        color: #32CD32;
    }
`

const RefundSpan = styled.span`
    white-space: no-wrap;
`

const ChatHeader = (props) => {
    const [lastSeen, setLastSeen] = useState('Offline')
    const {
        colorScheme,
        chat,
        auth
    } = props
    const { chatHeaderBackgroundColor, chatHeaderFontColor, chatBorderColor } = colorScheme
    const { role, isAdmin } = auth.user
    const { hide_earnings, enable_live_stream_report_for_content_manager } = auth.appSettings

    let hideEarnings = isAdmin
        ? (role === 'content_manager' && hide_earnings) || role === 'live_stream_manager'
        : true

    let receiverId = props.auth.user.isAdmin
        ? props.match.params.id || props.chat.selectedUserId
        : props.chat.selectedUserId

    const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
        ? chat.userProfileInfo[receiverId]
        : getCloudFrontAssetsUrl('faces/avatar.png')
    const profile = receiverProfileDetails.profile || getCloudFrontAssetsUrl('faces/avatar.png')
    const showViewGoLiveButton = receiverProfileDetails?.isLiveStreamAvailable === true && (['admin', 'sub_admin'].includes(role)) ? true : role === 'content_manager' && enable_live_stream_report_for_content_manager === true ? true : false
    const userWalletAmount = (userData) => {
        const walletAmount = _.get(userData, 'wallet_amount', false)
        return (['admin', 'sub_admin'].includes(role) && walletAmount !== false && walletAmount !== null)
            ? <span className='me-1 wallet-amount'><i className='fas fa-wallet me-1'></i>${walletAmount.toFixed(2)}</span>
            : null
    }

    const loadSocketEvent = () => {
        if (auth.user.isAdmin === true) {
            socket.on('ONLINE_USER_LIST', (users) => {
                if (users.includes(props.chat.selectedUserId)) {
                    setLastSeen('Online')
                } else {
                    setLastSeen('Offline')
                }
            })
            socket.on('USER_OFFLINE', async (userId) => {
                if (userId === props.chat.selectedUserId) {
                    props.updateUserLastSeen(userId)
                    setLastSeen(new Date())
                }
            })
            socket.on('UPDATE_USER_AMOUNT', async (userData) => {
                props.updateUserAmountSpent(userData)
            })
        }
    }

    const userLastSeen = (time) => {
        if (lastSeen === 'Online') return 'Online'
        if (lastSeen === 'Offline') return formatUserLastSeen(time)
        return formatUserLastSeen(lastSeen)
    }

    const refundAmount = () => {
        const voidAmount = receiverProfileDetails.totalVoidAmount
        const refundAmount = receiverProfileDetails.totalRefundAmount
        const chargeBackAmount = receiverProfileDetails.totalChargeBackAmount
        const chargeBackCount = receiverProfileDetails.totalChargeBackCount
        const totalRefundedAmount = voidAmount + refundAmount + chargeBackAmount + (25 * chargeBackCount)
        return (totalRefundedAmount > 0) ?
            <RefundSpan className='me-1 text-danger'>(${totalRefundedAmount.toFixed(2)})</RefundSpan>
            : <></>
    }

    useEffect(() => {
        if (props.auth.user.isAdmin) {
            socket.emit('GET_ONLINE_USER_LIST')
            const data = { adminId: props.auth.user._id, userId: props.match.params.id }
            socket.emit('ADMIN_IN_USER_CHAT', data)
        }
        loadSocketEvent()
    }, [props.chat.selectedUserId])

    const handleExitChat = () => {
        props.history.push('/admin/chat')
        props.setIsChatScreenOpen(false)
        props.setSelectedUserId('')
    }

    return (
        <ChatNewHeader
            className='p-2'
            backgroundColor={chatHeaderBackgroundColor}
            borderColor={chatBorderColor}
            color={chatHeaderFontColor}
            isLoading={!receiverProfileDetails.name ? 'true' : 'false'}
            showFullText={['admin', 'sub_admin', 'content_manager'].includes(role)}
            showViewGoLiveButton={showViewGoLiveButton}
        >
            <h4 className='d-flex flex-1 align-items-center mb-0'>
                <i className='fas fa-arrow-left d-inline d-lg-none mr-2' onClick={handleExitChat}></i>
                <Image alt='' draggable={false} className='img-fluid mr-2' src={profile} />
                <div className='d-flex flex-column'>
                    {receiverProfileDetails.name &&
                        <>
                            <p className='m-0 p-0 user-name'> {receiverProfileDetails.name}&nbsp;<UserNickName className='mt-1'>{receiverProfileDetails.nick_name}</UserNickName></p>
                            <span className='amount'>
                                {/* Wallet amount display */}
                                {userWalletAmount(receiverProfileDetails)}
                                {/* Wallet amount display */}
                                {!hideEarnings &&
                                    <Earning
                                        className='me-1'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={`/purchased-contents/${receiverId}`}
                                    >
                                        (${receiverProfileDetails.totalAmountSpent ? receiverProfileDetails.totalAmountSpent.toFixed(2) : '0.00'})
                                    </Earning>
                                }
                                {['admin', 'sub_admin'].includes(role) &&
                                    refundAmount(receiverProfileDetails)
                                }
                            </span>
                            {isAdmin &&
                                <UserLastSeen lastSeen={lastSeen}>
                                    {lastSeen === 'Online' ?
                                        <svg height="20" width="20">
                                            <circle cx="10" cy="10" r="5" fill="#32CD32" />
                                        </svg>
                                        :
                                        <></>
                                    }
                                    {userLastSeen(receiverProfileDetails.lastSeen)}
                                </UserLastSeen>
                            }
                        </>}
                </div>
            </h4>
            {/* Live stream button component */}
            <LiveStream />
        </ChatNewHeader>
    )
}

ChatHeader.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setIsChatScreenOpen: PropTypes.func.isRequired,
    setSelectedUserId: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateUserLastSeen: PropTypes.func.isRequired,
    updateUserAmountSpent: PropTypes.func.isRequired,
    handleTextMessageSend: PropTypes.func.isRequired,
    scrollToBottom: PropTypes.func.isRequired,
    canChat: PropTypes.bool.isRequired,
    setCanChat: PropTypes.func.isRequired,
    chatStartTime: PropTypes.number,
    setChatStartTime: PropTypes.func.isRequired,
    chatEndTime: PropTypes.number,
    setChatEndTime: PropTypes.func.isRequired,
    chatEndTimeWithDelay: PropTypes.number,
    setChatEndTimeWithDelay: PropTypes.func.isRequired,
    countDownDuration: PropTypes.number.isRequired,
    setCountDownDuration: PropTypes.func.isRequired,
    getBookedSlotData: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    socket: state.socket
})

export default connect(
    mapStateToProps,
    {
        setIsChatScreenOpen,
        setSelectedUserId,
        updateUserLastSeen,
        updateUserAmountSpent
    }
)(withRouter(ChatHeader))
