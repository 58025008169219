import {
    OPEN_COPY_TO_CLIPBOARD_TOAST,
    CLOSE_COPY_TO_CLIPBOARD_TOAST,
    OPEN_COPY_TO_CLIPBOARD_TOAST_WITH_OFFSET
} from '../actions/types'

const initialState = {
    isCopyToClipboardToastOpen: false,
    toastType: 'copy',
    // When no offset is provided, toast message will be displayed in center of screen with respect to x-axes of the screen
    // If you want to show toast message at specific position with respect to x-axes of the screen, you can use offset
    // offset format => '100px', '200px', '500px' ... etc
    offset: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_COPY_TO_CLIPBOARD_TOAST:
            return {
                ...state,
                isCopyToClipboardToastOpen: true,
                toastType: action.payload
            }
        case OPEN_COPY_TO_CLIPBOARD_TOAST_WITH_OFFSET:
            return {
                ...state,
                isCopyToClipboardToastOpen: true,
                toastType: action.payload.toastType,
                offset: action.payload.offset
            }
        case CLOSE_COPY_TO_CLIPBOARD_TOAST:
            return {
                ...state,
                isCopyToClipboardToastOpen: false,
                offset: ''
            }
        default:
            return state
    }
}
