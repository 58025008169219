import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import WebsiteFontColor from './../Layouts/WebsiteFontColor'
import styled from 'styled-components'
import { FILE_SIZE_LIMIT_IN_BYTE } from '../../utils/constant'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { getFileExtension, sortArrayById } from '../../utils/common'
import _ from 'lodash'
import { setWelcomeMessage } from '../../actions/chatActions'
import axios from 'axios'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import classNames from 'classnames'
import { getAppBaseUrl } from './../../actions/api'
import PreviewContent from '../content/PreviewContent'
import MediaSelectionPopup from '../media/MediaSelectionPopup'
const BASE_URL = getAppBaseUrl()

const useStyles = () => ({
    selectForm: {
        padding: '5px',
        height: '40px',
        border: 'none'
    }
})

const ButtonGroup = styled.div`
    width:100%;

    button.btn.mb-2.p-2.shadow-none.m-0:hover {
        background-color: ${props => props.content_color};
        color: ${props => props.content_font_color} !important;
        outline: 1px solid ${props => props.content_color} !important;
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.m-0.border-2:focus {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }
    button.btn.selectedOption {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 5px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.shadow-none.m-0:focus-visible, button.btn.mb-2.p-2.shadow-none.m-0:focus {
        outline: transparent !important;
    }

    button.rounded-start {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important;
    }

    button.rounded-end {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important;
    }

    @media (max-width: 1279px) and (min-width: 991px) {
        button.btn.mb-2.p-2.shadow-none.m-0 {
            font-size: 0.7125rem;
        }
    }
`

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

function WelcomeMessage(props) {
    const {
        content_color,
        content_font_color,
        site_font_color,
        button_shadow_color,
        card_background_color,
        content_unlock_minimum_amount,
        content_unlock_maximum_amount
    } = props.auth.appSettings
    const { allMedia } = props.media
    const [media, setMedia] = useState([])
    const [mediaPreview, setMediaPreview] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [uploadProgress, setUploadProgress] = useState({ type: '' })
    const [disableButtons, setDisableButtons] = useState(false)
    const [isPreviewModeEnable, setIsPreviewModeEnable] = useState(false)
    const [option, setOption] = useState('locked')
    const [amount, setAmount] = useState(20)
    const [message, setMessage] = useState('')
    const [helperModelOpen, setHelperModelOpen] = useState(false)
    const [messageType, setMessageType] = useState('text')
    const [isRearrangeModeEnable, setIsRearrangeModeEnable] = useState(false)
    const [dragId, setDragId] = useState('')
    const [delayDescription, updateDelayDescription] = useState('DELAY_BY_HOURS')
    const [delayHour, updateDelayHour] = useState(2)
    const [isLoaded, updateIsLoaded] = useState(false)
    const [messageId, setMessageId] = useState('')
    const [sendOptionType, updateSendOptionType] = useState('ONLY_NEW')
    const [isError, updateIsError] = useState(false)
    const [processing, updateProcessing] = useState(false)
    const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(-1)
    const [showMediaSelectionPopup, setShowMediaSelectionPopup] = useState(false)
    const [showMediaPreviewSelectionPopup, setShowMediaPreviewSelectionPopup] = useState(false)
    const [mediaPreviewIds, setMediaPreviewIds] = useState([])
    const [mediaIds, setMediaIds] = useState([])
    const [selectFromContentManager] = useState(true)
    const [contentMedia, setContentMedia] = useState([])
    const [contentMediaPreview, setContentMediaPreview] = useState([])
    const [contentIsPreviewModeEnable, setContentIsPreviewModeEnable] = useState(false)
    const [isRearrangeModeEnableForMedia, setIsRearrangeModeEnableForMedia] = useState(false)
    const [defaultMedia, setDefaultMedia] = useState([])
    const [format, setFormat] = useState('classic')
    const [mediaPreviewId, setMediaPreviewId] = useState(null)

    useEffect(() => {
        if (isLoaded === false) {
            axios.get(BASE_URL + '/admin/get-welcome-message').then(res => {
                if (res.status === 200) {
                    const data = res.data.data
                    if (data !== null) {
                        setMessageId(data._id)
                        setMessage(data.message)
                        setMessageType(data.type)
                        setAmount(data.amount)
                        const newUserMessageDelay = _.get(data, 'newUserMessageDelay', 'DELAY_BY_HOURS')
                        updateDelayDescription(newUserMessageDelay)
                        const sendTo = _.get(data, 'sendTo', 'ONLY_NEW')
                        updateSendOptionType(sendTo)
                        const delayHour = _.get(data, 'delayByHours', '2')
                        const delayByHour = (delayHour % 1 === 0) ? parseInt(delayHour) : delayHour
                        updateDelayHour(delayByHour)
                        const isError = _.get(data, 'isError', false)
                        if (isError === false) {
                            setContentMedia(data.media)
                            setMedia(data.media)
                            setDefaultMedia(data.media)
                            setContentMediaPreview(data.media_preview)
                            setMediaPreview(data.media_preview)
                            setMediaIds(data.media_item)
                            setMediaPreviewIds(data.media_preview_item ? [data.media_preview_item] : '')
                            setMediaPreviewId(data.media_preview_item)
                            if (data.media_preview.length > 0) {
                                setIsPreviewModeEnable(true)
                                setContentIsPreviewModeEnable(true)
                            }
                        } else {
                            updateIsError(true)
                        }
                        setOption(data.isLocked)
                        updateProcessing(data.processing)
                        setFormat(data.format)
                    }
                    updateIsLoaded(true)
                }
            })
        }
    }, [])

    const galleryFilesFromPreview = (files) => {
        if (mediaPreview.length === 0) {
            galleryFiles([files[0]], 'preview')
        } else {
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
    }

    const galleryFilesFromOriginal = (files) => {
        galleryFiles(files, 'original')
    }

    const galleryFiles = (files, name) => {
        if (isRearrangeModeEnable !== true) {
            const existFilesCount = selectFromContentManager ? contentMedia.length : media.length
            const uploadFileCount = 45 - existFilesCount < files.length ? 45 - existFilesCount : files.length
            const totalFilesCount = files.length + existFilesCount
            let type = 'gallery'
            for (let index = 0; index < uploadFileCount; index++) {
                const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
                if (!acceptExtension.includes(files[index].type)) {
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }
                if (totalFilesCount === 1) {
                    if (['video/mp4', 'video/quicktime'].includes(files[index].type)) {
                        type = 'video'
                    } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(files[index].type)) {
                        type = 'photo'
                    }
                }
            }
            const newUploadedFiles = []
            const mediaNewUploadedFiles = []
            for (let index = 0; index < uploadFileCount; index++) {
                const fileSize = _.get(files[index], 'size', -1)
                if (fileSize !== -1 && fileSize > FILE_SIZE_LIMIT_IN_BYTE) {
                    props.setSweetAlert({ description: `File ${files[index].name} size is too large.` })
                } else {
                    const file = files[index]
                    if (file) {
                        const newFileName = file.name
                        const newFileSize = file.size
                        const galleries = (name === 'original') ? media : mediaPreview
                        let findRecord = -1
                        if (name === 'original') {
                            findRecord = _.findIndex(galleries, function (n) {
                                return (n.is_new_file === true && n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                            })
                        }
                        if (findRecord === -1) {
                            let newFile = {
                                selectedFile: file,
                                renderFile: file.format === 'modern' ? file.path : URL.createObjectURL(file),
                                is_new_file: true
                            }
                            if (name === 'original') {
                                selectFromContentManager ? mediaNewUploadedFiles.push(newFile) : newUploadedFiles.push(newFile)
                            } else {
                                selectFromContentManager ? setContentMediaPreview([newFile]) : setMediaPreview([newFile])
                            }
                        }
                    }
                }
            }
            if (totalFilesCount > 45) {
                props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 45 images allowed.` })
            } else {
                setMessageType(type)
                let copyNewFiles = [...media]
                copyNewFiles = copyNewFiles.concat(newUploadedFiles)
                if (name === 'original') {
                    const existingMedia = format === 'modern' ? [] : defaultMedia
                    copyNewFiles = [...existingMedia, ...mediaNewUploadedFiles]
                    selectFromContentManager ? setContentMedia(copyNewFiles) : setMedia(copyNewFiles)
                }
            }
        }
    }

    const handleAddFile = (file, name) => {
        if (file) {
            const newFileName = file.name
            const newFileSize = file.size
            const galleries = (name === 'original') ? media : mediaPreview
            let findRecord = -1
            if (name === 'original') {
                findRecord = _.findIndex(galleries, function (n) {
                    return (n.is_new_file === true && n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                })
            }
            if (findRecord === -1) {
                let newFile = {
                    selectedFile: file,
                    renderFile: file.format === 'modern' ? file.path : URL.createObjectURL(file),
                    is_new_file: true
                }
                if (name === 'original') {
                    let copyNewFiles = [...media]
                    copyNewFiles = copyNewFiles.concat(newFile)
                    setMedia(copyNewFiles)
                } else {
                    selectFromContentManager ? setContentMediaPreview([newFile]) : setMediaPreview([newFile])
                }
            }
        }
    }

    const openDialog = (url, type) => {
        if (disableButtons === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    const uploadProgressInMb = (galleryIndex, progressNumber) => {
        let actualFileSize = 0
        let actualFileUploaded = '0'
        const type = _.get(uploadProgress, 'type', '')
        const selectedFile = type === 'original' ? media[galleryIndex].selectedFile : mediaPreview[galleryIndex].selectedFile
        actualFileSize = (_.get(selectedFile, 'size', 0) / 1024) / 1024
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text' style={{ color: site_font_color }}>Uploading {actualFileUploaded} MB Of {actualFileSize.toFixed(2)} MB</div>
    }

    const handleDeletePhoto = (index, name) => {
        setSelectedPreviewIndex(-1)
        if (name === 'preview') {
            selectFromContentManager ? setContentMediaPreview([]) : setMediaPreview([])
            setMediaPreviewIds([])
            if (contentMedia.length === 0) {
                setMessageType('text')
            }
        } else {
            if (media.length === 2) {
                setIsRearrangeModeEnable(false)
                setIsRearrangeModeEnableForMedia(false)
            }
            const copyMedia = selectFromContentManager ? [...contentMedia] : [...media]
            let mediaToRemove = copyMedia[index]
            const updatedFilesArray = [
                ...copyMedia.slice(0, index),
                ...copyMedia.slice(index + 1)
            ]
            const updatedMediaIdsArray = [
                ...mediaIds.slice(0, index),
                ...mediaIds.slice(index + 1)
            ]
            if (updatedFilesArray.length === 1) {
                const isNewFile = _.get(updatedFilesArray, '[0].is_new_file', false)
                let type
                if (isNewFile) {
                    if (['video/mp4', 'video/quicktime'].includes(updatedFilesArray[0].selectedFile.type)) {
                        type = 'video'
                    } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(updatedFilesArray[0].selectedFile.type)) {
                        type = 'photo'
                    }
                } else {
                    type = updatedFilesArray[0].content_type
                }
                setMessageType(type)
            }
            if (updatedFilesArray.length === 0) {
                setMessageType('text')
            }
            setMediaIds(updatedMediaIdsArray)
            selectFromContentManager ? setContentMedia(updatedFilesArray) : setMedia(updatedFilesArray)

            // Remove the item from the defaultMedia array if it exists
            const updatedDefaultMediaArray = defaultMedia.filter(item => item !== mediaToRemove)
            setDefaultMedia(updatedDefaultMediaArray)
        }
    }

    const reArrangeImage = (dragIndex, dropIndex) => {
        let copyNewFiles = selectFromContentManager ? [...contentMedia] : [...media]
        const draggedImage = copyNewFiles[dragIndex]
        copyNewFiles[dragIndex] = copyNewFiles[dropIndex]
        copyNewFiles[dropIndex] = draggedImage
        selectFromContentManager ? setContentMedia(copyNewFiles) : setMedia(copyNewFiles)
    }


    const handleDrag = (index) => {
        if (isRearrangeModeEnable === true || isRearrangeModeEnableForMedia) {
            setDragId(index)
        }
    }

    const handleDrop = (index) => {
        if ((isRearrangeModeEnable === true || isRearrangeModeEnableForMedia) && dragId !== '') {
            const copyNewFiles = selectFromContentManager ? [...contentMedia] : [...media]
            const dragFile = copyNewFiles[dragId]
            copyNewFiles.splice(dragId, 1)
            copyNewFiles.splice(index, 0, dragFile)
            selectFromContentManager ? setContentMedia(copyNewFiles) : setMedia(copyNewFiles)
            setDragId('')
        }
        setDragId('')
    }

    const handleGalleryChange = (e, name) => {
        const files = e.target.files
        if (name === 'preview') {
            if (files.length > 1) {
                return props.setSweetAlert({ description: 'You can not add more than one preview.' })
            } else {
                handleAddFile(files[0], name)
            }
        } else {
            galleryFiles(files, name)
        }
    }

    const uploadFileUsingPresignedUrl = async (contentType, url, body, galleryIndex = 0, type = 'original') => {
        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    setDisableButtons(false)
                    return props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })

                const object = {
                    progress: percentCompleted + '%',
                    index: galleryIndex,
                    progressNumber: percentCompleted,
                    type: type
                }
                setUploadProgress(object)
            }
        }
        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    const setWelcomeMessage = async (e) => {
        e.preventDefault()
        const finalMessage = message.trim()
        if (finalMessage === '' && messageType === 'text') {
            return props.setSweetAlert({ description: 'Please insert the message.' })
        }
        if (messageType !== 'text' && media.length < 1 && contentMedia.length < 1) {
            return props.setSweetAlert({ description: 'Please Select Media' })
        }
        if (mediaPreview.length > 1) {
            return props.setSweetAlert({ description: 'You can only select 1 preview content' })
        }
        if (option === 'locked' && media.length < 1 && contentMedia.length < 1) {
            return props.setSweetAlert({ description: 'Please Select Media' })
        }
        if (option === 'locked' && messageType === 'text') {
            return props.setSweetAlert({ description: 'In order to send as locked, you must upload content.' })
        }

        if (option === 'locked') {
            if ((amount < content_unlock_minimum_amount) || (amount > content_unlock_maximum_amount)) {
                return props.setSweetAlert({ description: `Choose an amount between ${content_unlock_minimum_amount} and ${content_unlock_maximum_amount}` })
            }
        }

        let messageObject = {
            sender: props.chat.selectedModelId,
            type: messageType,
            fromAdmin: true,
            message: finalMessage,
            isRead: 0,
            processing: messageType === 'text' ? false : true,
            isLocked: option,
            amount: amount,
            send_to: sendOptionType,
            isWelcomeMessage: true,
            created: new Date(),
            new_user_message_delay: delayDescription,
            _id: messageId,
            media: [],
            media_preview: [],
            isSelectedContentManager: selectFromContentManager,
            media_item: [],
            media_preview_item: mediaPreviewId,
            format: format
        }

        if (delayDescription === 'DELAY_BY_HOURS') {
            messageObject.delay_by_hours = Number(delayHour).toFixed(1)
        }

        for (let index = 0; index < media.length; index++) {
            const element = media[index]
            if (element.is_new_file === true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    return props.setSweetAlert({ description: `Image Number ${index + 1} media format is not supported.` })
                }
            }
        }

        for (let index = 0; index < mediaPreview.length; index++) {
            const element = mediaPreview[index]
            if (element.is_new_file === true && element.is_selected_from_original_file !== true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    return props.setSweetAlert({ description: 'Preview media format is not supported.' })
                }
            }
        }

        let contentLeftForProcessing = 0
        setDisableButtons(true)
        for (let index = 0; index < contentMedia.length; index++) {
            const element = contentMedia[index]
            if (element.is_new_file === true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                let fileExtensionForPresignedUrl = 'photo'
                if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                    fileExtensionForPresignedUrl = 'video'
                }
                if (element.selectedFile.format === 'modern') {
                    messageObject.media_item.push(element.selectedFile._id)
                    messageObject.media.push({
                        id: element.selectedFile._id,
                        content_type: element.selectedFile.type.includes('image') ? 'photo' : 'video',
                        url: element.selectedFile.path
                    })
                } else {
                    let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl)
                    if (presignedUrlData.presigned_url !== '') {
                        let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index)
                        if (uploadFile === true) {
                            messageObject.media.push({
                                url: presignedUrlData.file_name,
                                thumbnail_url: '',
                                blur_url: '',
                                is_process: true,
                                content_type: fileExtensionForPresignedUrl,
                                is_error: false,
                                is_new_file: true
                            })
                            contentLeftForProcessing++
                        } else {
                            setDisableButtons(false)
                            return props.setSweetAlert({ description: presignedUrlData.message })
                        }
                    } else {
                        setDisableButtons(false)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                }

            } else {
                // messageObject.media.push(element)
                if (element._id) {
                    messageObject.media_item.push(element._id)
                    messageObject.media.push({ id: element._id, content_type: element.content_type, url: element.url })
                } else {
                    messageObject.media.push(element)
                }
            }

        }


        // preview image upload
        for (let index = 0; index < contentMediaPreview.length; index++) {
            const element = contentMediaPreview[index]
            if (element.is_new_file === true) {

                let fileExtension = getFileExtension(element.selectedFile.name)
                let fileExtensionForPresignedUrl = 'photo'
                if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                    fileExtensionForPresignedUrl = 'video'
                }
                if (element.selectedFile.format === 'modern') {
                    messageObject.media_preview_item = element.selectedFile._id
                    messageObject.media_preview.push({
                        id: element.selectedFile._id,
                        content_type: element.selectedFile.type.includes('image') ? 'photo' : 'video',
                        url: element.selectedFile.path
                    })
                } else {
                    let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl)
                    if (presignedUrlData.presigned_url !== '') {
                        let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                        if (uploadFile === true) {
                            messageObject.media_preview.push({
                                url: presignedUrlData.file_name,
                                thumbnail_url: '',
                                is_process: true,
                                content_type: fileExtensionForPresignedUrl,
                                is_new_file: true
                            })
                            contentLeftForProcessing++
                        } else {
                            setDisableButtons(false)
                            return props.setSweetAlert({ description: presignedUrlData.message })
                        }
                    } else {
                        setDisableButtons(false)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                }

            } else {
                if (element._id) {
                    messageObject.media_preview_item = element._id
                    messageObject.media_preview.push({ id: element._id, content_type: element.content_type, url: element.url })
                } else {
                    messageObject.media_preview = [element]
                }
            }
        }

        messageObject.contentLeftForProcessing = contentLeftForProcessing
        if (contentLeftForProcessing === 0) {
            messageObject.processing = false
        }
        const isSuccess = await props.setWelcomeMessage(messageObject)
        setDisableButtons(false)
        props.handleWelcomeMassDlgClose()
        if (isSuccess) {
            props.setSweetAlert({ description: 'Welcome message set successfully.' })
        }
        return
    }

    const onChangePreviewMode = () => {
        if (isPreviewModeEnable === true || contentIsPreviewModeEnable === true) {
            selectFromContentManager ? setContentMediaPreview([]) : setMediaPreview([])
            setMediaPreviewIds([])
            setSelectedPreviewIndex(-1)
        }
        selectFromContentManager ? setContentIsPreviewModeEnable(!contentIsPreviewModeEnable) : setIsPreviewModeEnable(!isPreviewModeEnable)
    }

    const onMarkAsPreview = (previewMedia, index) => {
        if (previewMedia.is_process === false) {
            previewMedia.is_selected_from_original_file = true
        }
        selectFromContentManager ? setContentMediaPreview([previewMedia]) : setMediaPreview([previewMedia])
        setSelectedPreviewIndex(index)
        setMediaPreviewIds([previewMedia.id])
    }


    const handleMedia = (selectedMediaIds, name) => {
        if (name === 'preview') {
            setShowMediaPreviewSelectionPopup(false)
            if (selectedMediaIds.length > 1) {
                props.setSweetAlert({ description: 'You can not add more than one preview.' })
                return
            }
        } else {
            setShowMediaSelectionPopup(false)
        }

        const updatedMedia = [...allMedia, ...defaultMedia]
        const selectedMediaObjects = updatedMedia.filter(media => selectedMediaIds.includes(media._id))
        const uniqueMediaObjects = selectedMediaObjects.filter((obj, index, self) =>
            index === self.findIndex((t) => t._id === obj._id)
        )

        const sortedMedia = sortArrayById(uniqueMediaObjects, selectedMediaIds)
        if (sortedMedia.length > 0) {
            const selectedFiles = sortedMedia.map(item => {
                const type = item.type ? item.type : item.content_type
                return {
                    _id: item._id,
                    path: item.path || item.url,
                    small_thumb: item.small_thumb || item.thumb || item.thumbnail_url,
                    format: 'modern',
                    type: type === 'photo' ? 'image/jpeg' : 'video/mp4',
                    size: parseInt(item.size),
                    name: item._id + (type === 'photo' ? '.jpeg' : '.mp4')
                }
            })
            galleryFiles(selectedFiles, name)
        }
    }


    useEffect(() => {
        const selectedMediaIds = contentMedia.filter(file => file.selectedFile).map(file => file.selectedFile._id)
        if (selectedMediaIds.length > 0) {
            setMediaIds(selectedMediaIds)
        }
    }, [contentMedia])

    useEffect(() => {
        const selectedMediaPreviewIds = contentMediaPreview.filter(file => file.selectedFile).map(file => file.selectedFile._id)
        if (selectedMediaPreviewIds.length > 0) {
            setMediaPreviewIds(selectedMediaPreviewIds)
        }
    }, [contentMediaPreview])

    return (<>
        <WebsiteFontColor>
            {isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openDialog('', '') }}
                    type={type}
                />
            }
            {isDialogOpen === false &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Welcome Message</h5>
                                <HelperButton disabled={disableButtons} onClick={props.setHelperModelOpen} style={{ backgroundColor: 'transparent', color: content_color }}>
                                    <i className='fas fa-question-circle'></i>
                                </HelperButton>
                                <button className='close' onClick={props.handleWelcomeMassDlgClose} style={{ color: site_font_color, opacity: 1 }} disabled={disableButtons} />
                            </div>
                            {isLoaded === false ?
                                <div className='text-center'>
                                    <div className='spinner-border' role='status' style={{ color: site_font_color }}>
                                        <span className='sr-only'>Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className='modal-body'>
                                    <div className='container'>
                                        <div className='form-row'>
                                            <div className='form-group col'>
                                                <textarea
                                                    id='description'
                                                    name='description'
                                                    className='form-control'
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    disabled={disableButtons}
                                                    placeholder='Write your message...'
                                                />
                                            </div>
                                        </div>
                                        {isError === true &&
                                            <label className='d-flex justify-content-center mt-2' style={{ color: site_font_color }}><span className='text-danger' style={{ fontSize: '24px' }}>*</span>Error in upload content. Please try again.</label>
                                        }
                                        {processing === true && isError !== true ?
                                            <div className='row justify-content-center ml-2 mt-2'>
                                                The Content is in under process. It will be visible to users when processing is completed.
                                            </div>
                                            :
                                            <>
                                                <PreviewContent
                                                    media={media}
                                                    contentManagerMedia={contentMedia}
                                                    mediaPreview={mediaPreview}
                                                    contentMediaPreview={contentMediaPreview}
                                                    isLoading={disableButtons}
                                                    handleDeletePhoto={handleDeletePhoto}
                                                    handleDrag={handleDrag}
                                                    handleDrop={handleDrop}
                                                    reArrangeImage={reArrangeImage}
                                                    uploadProgress={uploadProgress}
                                                    uploadProgressInMb={uploadProgressInMb}
                                                    handleGalleryChange={handleGalleryChange}
                                                    option={option}
                                                    onChangePreviewMode={onChangePreviewMode}
                                                    isPreviewModeEnable={isPreviewModeEnable}
                                                    isPreviewModeEnableForMedia={contentIsPreviewModeEnable}
                                                    galleryFilesFromPreview={galleryFilesFromPreview}
                                                    openDialog={openDialog}
                                                    galleryFilesFromOriginal={galleryFilesFromOriginal}
                                                    isRearrangeModeEnable={isRearrangeModeEnable}
                                                    setIsRearrangeModeEnable={setIsRearrangeModeEnable}
                                                    isRearrangeModeEnableForMedia={isRearrangeModeEnableForMedia}
                                                    setIsRearrangeModeEnableForMedia={setIsRearrangeModeEnableForMedia}
                                                    selectedPreviewIndex={selectedPreviewIndex}
                                                    onMarkAsPreview={onMarkAsPreview}
                                                    isSelectedFromContentManager={selectFromContentManager}
                                                    setShowMediaSelectionPopup={setShowMediaSelectionPopup}
                                                    setShowMediaPreviewSelectionPopup={setShowMediaPreviewSelectionPopup}
                                                    requestFrom='welcome-message'
                                                />
                                            </>
                                        }
                                        <ButtonGroup className='button-group d-flex mt-5' content_color={content_color} content_font_color={content_font_color} button_shadow_color={button_shadow_color}>
                                            <button type='button'
                                                className={classNames('btn col mb-2 p-2 m-0 rounded-start', { 'border-2 selectedOption': option === 'locked', 'shadow-none': option !== 'locked' })}
                                                onClick={() => setOption('locked')}
                                                style={{
                                                    borderRadius: '0% 0% 0% 0%',
                                                    backgroundColor: option === 'locked' && content_color,
                                                    color: option === 'locked' ? content_font_color : site_font_color,
                                                    borderColor: option !== 'locked' && content_color
                                                }}
                                                disabled={disableButtons}
                                            >
                                                Locked
                                            </button>
                                            <button type='button'
                                                className={classNames('btn col mb-2 p-2 m-0 rounded-0', { 'border-2 selectedOption': option === 'free', 'shadow-none': option !== 'free' })}
                                                onClick={() => {
                                                    setOption('free')
                                                    setMediaPreview([])
                                                    setIsPreviewModeEnable(false)
                                                    setContentMediaPreview([])
                                                    setMediaPreviewIds([])
                                                    setContentIsPreviewModeEnable(false)
                                                }}
                                                style={{
                                                    borderRadius: '0% 0% 0% 0%',
                                                    backgroundColor: option === 'free' && content_color,
                                                    color: option === 'free' ? content_font_color : site_font_color,
                                                    borderColor: option !== 'free' && content_color
                                                }}
                                                disabled={disableButtons}
                                            >
                                                Free
                                            </button>
                                        </ButtonGroup>
                                        <div className='row mt-2'>
                                            <div className='form-group'>
                                                <div className='from-check'>
                                                    <input
                                                        className='form-check-input ml-2'
                                                        type='radio'
                                                        value='DELAY_BY_HOURS'
                                                        checked={delayDescription === 'DELAY_BY_HOURS'}
                                                        onChange={(e) => updateDelayDescription(e.target.value)}
                                                        name='delay_once_they_subscribe_by'
                                                        id='delay_once_they_subscribe_by'
                                                        disabled={disableButtons}
                                                    />
                                                    <label className='form-check-label ml-5 col-12' style={{ display: 'block' }} htmlFor='delay_once_they_subscribe_by'>delay once they subscribe by
                                                        <input
                                                            className="mr-2"
                                                            type='text'
                                                            value={delayHour}
                                                            onChange={(e) => updateDelayHour(e.target.value)}
                                                            style={{ width: '40px', marginLeft: '2%', textAlign: 'center', lineHeight: '1.2', borderRadius: '5px' }}
                                                            name='sendToHour'
                                                            id='sendToHour'
                                                            disabled={disableButtons}
                                                        />hours
                                                    </label>
                                                    <input
                                                        className='form-check-input ml-2'
                                                        type='radio'
                                                        value='IMMEDIATELY'
                                                        checked={delayDescription === 'IMMEDIATELY'}
                                                        onChange={(e) => updateDelayDescription(e.target.value)}
                                                        name='immediately_once_they_subscribe'
                                                        id='immediately_once_they_subscribe'
                                                        disabled={disableButtons}
                                                    />
                                                    <label className='form-check-label ml-5 col-12' htmlFor='immediately_once_they_subscribe'>immediately once they subscribe</label>
                                                </div>
                                            </div>
                                        </div>
                                        {option === 'locked' &&
                                            <div className='form-row'>
                                                <div className='form-group col col-md-6 col-lg-6'>
                                                    <label htmlFor='amount'>Amount</label>
                                                    <div className='input-group'>
                                                        <div className='input-group-prepend'>
                                                            <span className='input-group-text form-control'>$</span>
                                                        </div>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id='amount'
                                                            name='amount'
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                            required
                                                            min={5}
                                                            max={450}
                                                            disabled={disableButtons}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className='row'>
                                            <div className='col'>
                                                <button
                                                    className='btn btn-block'
                                                    style={{
                                                        backgroundColor: content_color,
                                                        color: content_font_color,
                                                        boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                                    }}
                                                    onClick={setWelcomeMessage}
                                                    disabled={disableButtons}
                                                >
                                                    {disableButtons === true && <span className="spinner-border spinner-border-sm mr-2"></span>} {messageId === '' ? 'Save' : 'Update'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {helperModelOpen === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                <h5 className='modal-title'>Send to: </h5>
                                <button className='close' onClick={(e) => {
                                    e.preventDefault()
                                    setHelperModelOpen(false)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <p>The options for Send to are:</p>
                                <ul>
                                    <li><b>Both current and future subscribers:</b> current subscribers will receive the message immediately and future subscribers will also receive the message after they join. This is how the old Mass Message worked. All messages you sent previously will continue to have this option, unless you change it.</li>
                                    <li><b>Only current subscribers:</b> Only current subscribers will receive the message. This option can be used for messages that have a time element. For example, if you’re wishing your subscribers a happy Valentines Day you wouldn’t want this message to be sent in the future to new subscribers.</li>
                                    <li><b>Only future subscribers:</b> Only future subscribers will receive it.  While we have a new Welcome Message feature for new subscribers you might want to use this choice to send followup messages to new users a few days after they join.  For example, you could ask them how they like the site after the first few days.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </WebsiteFontColor>
        {
            (showMediaSelectionPopup || showMediaPreviewSelectionPopup) &&
            <MediaSelectionPopup
                title={showMediaPreviewSelectionPopup ? 'Select Preview' : 'Select Media'}
                onClose={() => { showMediaSelectionPopup ? setShowMediaSelectionPopup(false) : setShowMediaPreviewSelectionPopup(false) }}
                prevSelectedMedia={showMediaSelectionPopup ? mediaIds : mediaPreviewIds}
                onSelection={(media) => { handleMedia(media, showMediaSelectionPopup ? 'original' : 'preview') }}
            />
        }
    </>
    )
}

WelcomeMessage.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    handleWelcomeMassDlgClose: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    setHelperModelOpen: PropTypes.func.isRequired,
    setWelcomeMessage: PropTypes.func.isRequired,
    media: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog,
    chat: state.chat,
    media: state.media
})

export default connect(
    mapStateToProps, { setSweetAlert, setWelcomeMessage }
)(withRouter(withStyles(useStyles)(WelcomeMessage)))
