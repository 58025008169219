import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

function Button(props) {
    const buttonType = _.get(props, 'type', 'button')
    return <button
        className={classNames('btn', props.classes)}
        type={buttonType}
        style={{
            backgroundColor: props.backgroundColor || props.auth.appSettings.content_color,
            color: props.fontColor || props.auth.appSettings.content_font_color,
            ...props.style
        }}
        disabled={props.loading}
        onClick={props.onClick}>
        {props.children}
    </button>

}

Button.propTypes = {
    loading: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.any,
    onClick: PropTypes.func,
    classes: PropTypes.string,
    style: PropTypes.object,
    backgroundColor: PropTypes.string,
    fontColor: PropTypes.string
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(Button))
