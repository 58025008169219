import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { logoutUser } from '../../actions/authActions'
import { toggleNotificationPopup, toggleSideBar } from './../../actions/utilityActions'
import { getNextMeetingTime } from '../../actions/chatActions'
import socket from '../../utils/socket'
import { resetCurrentPage } from '../../actions/blogActions'
import classNames from 'classnames'

const Nav = styled.nav`
    background-color: ${props => props.content_color};
    padding: ${props => props.padding};
    z-index: 1000 !important;
    border-top: 1px solid ${props => props.content_font_color};
`

const NavLinkStyled = styled(NavLink)`
    color: ${props => props.color} !important;

    :hover {
        color: ${props => props.color};
    }

    span {
        font-size: 13px;
        text-transform: capitalize;
    }
`

const NavLinkStyledA = styled.a`
    color: ${props => props.color} !important;

    :hover {
        color: ${props => props.color};
    }

    span {
        font-size: 13px;
        text-transform: capitalize;
    }
`

const ColDiv = styled.div`
    padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px) !important;
    
    .nav-link {
        padding-top: 8px !important;
        border-bottom: 4px solid transparent;
    }
    
    .active {
        background: ${props => props.color}17;
        border-bottom: 4px solid ${props => props.color};
        text-decoration: none;
        font-weight: bold;
    }

    .unread-count {
        position: absolute;
        top: -5px;
        right: -15px;
        background: #ff0000;
        color: #fff;
        font-size: 9px;
        border-radius: 50%;
        min-height: 22px;
        min-width: 22px;
        text-align: center;
        line-height: 22px;
    }

    .unread-count-position {
        right: -2px !important; 
    }
`

const Icons = styled.i`
    font-size: 1.3rem;
`

class BottomNavbar extends Component {

    constructor(props) {
        super(props)

        this.onLogout = this.onLogout.bind(this)
    }

    onLogout(e) {
        e.preventDefault()
        this.props.resetCurrentPage()
        this.props.logoutUser(this.props.history)
        socket.emit('LOGOUT_USER')
        const pathName = this.props.history.location.pathname
        if (pathName.includes('/feed/') === true) {
            this.props.history.push('/')
        }
    }

    render() {
        const {
            content_color,
            content_font_color,
            navbar_font_weight,
            navbar_padding,
            links_menu
        } = this.props.auth.appSettings

        const { isAuthenticated, user, counts, customMenuLinks, linkPageData } = this.props.auth
        let padding = (navbar_padding === undefined || navbar_padding === '') ? '0.5rem 1rem' : navbar_padding

        let chatUrl = '/chat'

        if ((user.ccbillSubscriptionStatus === '1') || user.ccbillSubscriptionStatus === '2') {
            chatUrl = '/private-chat'
        }

        const pathName = this.props.location.pathname

        let allowLinksMenu = false
        if (!_.isEmpty(user)) {
            if (user.isAdmin) {
                chatUrl = '/admin/chat'
                if (user.role === 'admin') {
                    allowLinksMenu = links_menu.super_admin
                } else {
                    allowLinksMenu = links_menu.admin
                }
            } else {
                if (user.ccbillSubscriptionStatus === '1' || user.ccbillSubscriptionStatus === '2') {
                    allowLinksMenu = links_menu.subscriber
                } else {
                    allowLinksMenu = links_menu.user
                }
            }
        } else {
            allowLinksMenu = links_menu.guest
        }

        let getMoreLinks
        if (customMenuLinks.length > 0 || (allowLinksMenu && linkPageData.length > 0)) {
            let totalLinksCount = 0
            if (customMenuLinks && customMenuLinks.length > 0) {
                if (customMenuLinks.length === 1) {
                    totalLinksCount += 1
                } else {
                    totalLinksCount = customMenuLinks.length
                }
            }
            if (allowLinksMenu && linkPageData && linkPageData.length > 0) {
                totalLinksCount += 1
            }

            if (totalLinksCount === 1) {
                if (allowLinksMenu && linkPageData && linkPageData.length > 0) {
                    getMoreLinks = (<NavLinkStyled to={'/links'} className={classNames('nav-link justify-content-center', { 'active': pathName === '/links' })} color={content_font_color} fontWeight={navbar_font_weight}>
                        <div className='text-center'>
                            <div><Icons className='fas fa-ellipsis-h'></Icons></div>
                            <span>Links</span>
                        </div>
                    </NavLinkStyled>)
                } else {
                    getMoreLinks = (<NavLinkStyledA href={customMenuLinks[0].url} target={customMenuLinks[0].open_in_new_tab ? '_blank' : '_self'} rel='noopener noreferrer' className='nav-link justify-content-center' color={content_font_color} fontWeight={navbar_font_weight}>
                        <div className='text-center'>
                            <div><Icons className='fas fa-ellipsis-h'></Icons></div>
                            <span>{customMenuLinks[0].name.length > 5 ? `${customMenuLinks[0].name.substring(0, 5)}...` : customMenuLinks[0].name}</span>
                        </div>
                    </NavLinkStyledA>)
                }
            } else {
                getMoreLinks = (<NavLinkStyled to={'/more-links'} className={classNames('nav-link justify-content-center', { 'active': ((pathName === '/links') || (pathName === '/more-links')) })} color={content_font_color} fontWeight={navbar_font_weight}>
                    <div className='text-center'>
                        <div><Icons className='fas fa-ellipsis-h'></Icons></div>
                        <span>More</span>
                    </div>
                </NavLinkStyled>)
            }
        }

        if (this.props.auth.isSupport) {
            return (
                <></>
            )
        }

        if (this.props.chat && this.props.chat.isChatScreenOpen) {
            return (
                <></>
            )
        }

        return (
            <Nav id='BottomNavBar' className='fixed-bottom d-lg-none p-0' role='navigation' padding={padding} content_color={content_color} content_font_color={content_font_color}>
                <div className='row m-0'>
                    <ColDiv className='col' color={content_font_color}>
                        <NavLinkStyled exact to='/' className='nav-link justify-content-center' activeClassName='active' color={content_font_color} fontWeight={navbar_font_weight}>
                            <div className='text-center'>
                                <div><Icons className='fas fa-home'></Icons></div>
                                <span>Feed</span>
                            </div>
                        </NavLinkStyled>
                    </ColDiv>
                    <ColDiv className='col chat-nav' color={content_font_color}>
                        <NavLinkStyled exact to={chatUrl} className='nav-link justify-content-center' activeClassName='active' color={content_font_color} fontWeight={navbar_font_weight}>
                            <div className='text-center position-relative'>
                                <div><Icons className='fas fa-comment-alt'></Icons></div>
                                <span>Chat</span>
                                {
                                    (counts.userUnreadMessage > 0 && isAuthenticated) &&
                                    <span className='unread-count'>{counts.userUnreadMessage > 99 ? '99+' : counts.userUnreadMessage}</span>
                                }
                            </div>
                        </NavLinkStyled>
                    </ColDiv>
                    {user.role === 'live_stream_manager' ?
                        <></>
                        :
                        user.isAdmin ?
                            <ColDiv className='col' color={content_font_color}>
                                <NavLinkStyled to='/admin/chat/tips-list' className='nav-link justify-content-center' activeClassName='active' color={content_font_color} fontWeight={navbar_font_weight}>
                                    <div className='text-center position-relative'>
                                        <div><Icons className='fas fa-dollar-sign'></Icons></div>
                                        <span>Tips</span>
                                        {
                                            this.props.auth.counts.modelUnreadTips > 0 &&
                                            <span className='unread-count'>
                                                {this.props.auth.counts.modelUnreadTips > 99 ? '99+' : this.props.auth.counts.modelUnreadTips}
                                            </span>
                                        }
                                    </div>
                                </NavLinkStyled>
                            </ColDiv> :
                            <ColDiv className='col' color={content_font_color}>
                                <NavLinkStyled to='/tips' className='nav-link justify-content-center' activeClassName='active' color={content_font_color} fontWeight={navbar_font_weight}>
                                    <div className='text-center'>
                                        <div><Icons className='fas fa-dollar-sign'></Icons></div>
                                        <span>Tips</span>
                                    </div>
                                </NavLinkStyled>
                            </ColDiv>
                    }
                    {isAuthenticated &&
                        <ColDiv className='col' color={content_font_color}>
                            <NavLinkStyled to='/profile' className='nav-link justify-content-center' activeClassName='active' color={content_font_color} fontWeight={navbar_font_weight}>
                                <div className='text-center position-relative'>
                                    <div><Icons className='fas fa-user-circle'></Icons></div>
                                    <span>Account</span>
                                    {
                                        this.props.userInfluencerHelp.unread_count > 0 &&
                                        <span className='unread-count unread-count-position'>
                                            {this.props.userInfluencerHelp.unread_count > 99 ? '99+' : this.props.userInfluencerHelp.unread_count}
                                        </span>
                                    }
                                </div>
                            </NavLinkStyled>
                        </ColDiv>}
                    {user.isAdmin && user.role !== 'live_stream_manager' && <ColDiv className='col' color={content_font_color}>
                        <NavLinkStyled to='/admin/reporting' className={classNames('nav-link justify-content-center', { 'active': ((pathName === '/admin/settings') || (pathName === '/admin/reporting') || (pathName === '/links') || (pathName === '/more-links')) })} color={content_font_color} fontWeight={navbar_font_weight}>
                            <div className='text-center'>
                                <div><Icons className='fas fa-ellipsis-h'></Icons></div>
                                <span>More</span>
                            </div>
                        </NavLinkStyled>
                    </ColDiv>}
                    {(!user.isAdmin && getMoreLinks) && <ColDiv className='col' color={content_font_color}>{getMoreLinks}</ColDiv>}
                </div>
            </Nav>
        )
    }
}

BottomNavbar.propTypes = {
    auth: PropTypes.object.isRequired,
    chatBookingTimer: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    classes: PropTypes.object,
    logoutUser: PropTypes.func.isRequired,
    toggleSideBar: PropTypes.func.isRequired,
    getNextMeetingTime: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    socket: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    resetCurrentPage: PropTypes.func.isRequired,
    toggleNotificationPopup: PropTypes.func.isRequired,
    utility: PropTypes.object.isRequired,
    influencerHelp: PropTypes.object,
    userInfluencerHelp: PropTypes.object,
    location: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    chatBookingTimer: state.chatBookingTimer,
    socket: state.socket,
    promotion: state.promotion,
    utility: state.utility,
    influencerHelp: state.influencerHelp,
    userInfluencerHelp: state.userInfluencerHelp,
    chat: state.chat
})

export default connect(
    mapStateToProps,
    { logoutUser, toggleSideBar, getNextMeetingTime, resetCurrentPage, toggleNotificationPopup }
)(withRouter(BottomNavbar))
