import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'

const AbsolutePositionDiv = styled.div`
    position: fixed;
    left: ${props => props.offset ? props.offset : '50%'};
    right: 0;
    top: 70vh;
    z-index: 99999;
    background: ${props => props.background};
    transform: translateX(-50%);
    max-width: 80vw;
`

const CopyToClipboard = (props) => {
    const { card_background_color } = props.auth.appSettings
    const { isCopyToClipboardToastOpen, offset } = props.copyToClipboard

    return isCopyToClipboardToastOpen ?
        <WebsiteFontColor>
            <AbsolutePositionDiv className='toast text-center show' offset={offset} background={card_background_color}>
                <div className='toast-body'>
                    {props.toastContent}
                </div>
            </AbsolutePositionDiv>
        </WebsiteFontColor>
        : <></>
}

CopyToClipboard.propTypes = {
    auth: PropTypes.object.isRequired,
    copyToClipboard: PropTypes.object.isRequired,
    toastContent: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    copyToClipboard: state.copyToClipboard
})

export default connect(
    mapStateToProps
)((CopyToClipboard))
